import { getLayout } from '@components/Layout';
import MyButton from '@components/Button';
import Link from 'next/link';

export default function Custom404() {
  return (
    <div className="flex-1 bg-white flex flex-col items-center py-24px">
      <p
        className="font-textBold font-bold text-blue-main"
        style={{ fontSize: 32 }}>
        Chức năng đang được nâng cấp
      </p>
      <p className="font-textBold font-bold text-blue-main text-14 mt-16px">
        Đội ngũ lập trình viên của chúng tôi đang làm việc để nâng cao trải
        nghiệm của bạn tại RealTech247
      </p>
      <img alt="icon" src="/svg/maintenance2.svg" className="w-300px h-400px" />
      <Link href="/">
        <div>
          <MyButton label="Trở về trang chủ" />
        </div>
      </Link>
    </div>
  );
}

Custom404.getLayout = getLayout;
